import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import profileService from '../services/profileService';

const firebaseConfig = {
  apiKey: 'AIzaSyBBRjOYgOoPPpBZzzXsrDTJBrlX-9UOj70',
  authDomain: 'mynyfy-buyer.firebaseapp.com',
  databaseURL: 'https://mynyfy-buyer.firebaseio.com',
  projectId: 'mynyfy-buyer',
  storageBucket: 'mynyfy-buyer.firebasestorage.app',
  messagingSenderId: '146546042451',
  appId: '1:146546042451:web:c0454e02ef895ba63c88a6',
  measurementId: 'G-4RGQCJZ3J3',
};

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const requestForToken = async ({ dispatch, successAction }) => {
  getToken(messaging, {
    vapidKey:
      'BBojx-EBXdqmYleNo-pfdcOcmVWaYqi6oS4Blew7DNDl7JCB_FlIebv5wdNcqMlgLD9qr8xj8AeinfSNoQLyOvM',
  })
    .then((currentToken) => {
      if (currentToken) {
        // console.log('current token for client: ', currentToken);
        let oldToken = localStorage.getItem('fbt');
        if (oldToken && oldToken === currentToken) {
          // do nothing
        } else {
          localStorage.setItem('fbt', currentToken);
          dispatch(successAction(currentToken));
          profileService.sendFirebaseToken({ deviceId: currentToken });
        }
      }
    })
    .catch((err) => {});
};
