import _ from 'lodash';
import { CustomerSubscriptionType } from '../../utils/enums';
import productOrderService from '../../services/productOrderService';
import { snackbarInfo } from '../../redux/actions/snackbar.action';
import { getShippingCharges } from './AddProductHelper';

export const productsDiscountValue = (products) => {
  let productsDiscount = products.reduce((acc, e) => {
    return e.discountedPrice || (e.discount && e.discountedPrice == 0)
      ? acc + (e.price - e.discountedPrice) * e.count
      : acc;
  }, 0);
  return productsDiscount;
};

export const couponDiscountValue = ({ promos, brandId, shippingCharges, priceDetails }) => {
  if (promos) {
    let selectedPromo = promos?.find((e) => e.brand == brandId);
    if (selectedPromo) {
      let couponDiscount = selectedPromo?.actualPrice || 0;
      if (couponDiscount) {
        let productsDiscount = priceDetails.productsDiscount;
        let totalDiscount = couponDiscount + productsDiscount;
        let cartValue = (Number(shippingCharges) || 0) + (priceDetails?.price || 0);
        let finalDiscount =
          couponDiscount == 0
            ? 0
            : totalDiscount > cartValue
            ? productsDiscount >= cartValue
              ? 0
              : cartValue - productsDiscount
            : couponDiscount;
        return finalDiscount;
      } else return 0;
    } else return 0;
  } else return 0;
};

const passDiscountValue = ({ selectedPack, priceDetails, shippingCharges }) => {
  if (selectedPack?.subscriptionId?.type == CustomerSubscriptionType.PASS) {
    let utilizedAmount =
      (selectedPack?.discountAvailed || 0) +
      (selectedPack?.hold?.reduce((acc, curr) => acc + curr.discountAvailed, 0) || 0);
    let passAmount = selectedPack.subscriptionId?.amount - utilizedAmount;
    let toPayObj = { priceDetails, shippingCharges };
    return toPayHandler(toPayObj) > passAmount ? passAmount : toPayHandler(toPayObj);
  } else return 0;
};

export const toPayHandler = ({ priceDetails, shippingCharges }) => {
  let price = priceDetails?.totalPrice;
  let finalPrice = price - priceDetails.discount - priceDetails.productsDiscount;
  finalPrice = finalPrice + Number(shippingCharges || 0);
  return finalPrice || (finalPrice == 0 ? finalPrice : 'NA');
};

export const finalPriceHandler = ({ selectedPack, priceDetails, shippingCharges }) => {
  return (
    toPayHandler({ priceDetails, shippingCharges }) -
    passDiscountValue({ selectedPack, priceDetails, shippingCharges })
  )?.toFixed(2);
};

export const orderSplitHandler = async ({
  body,
  userProducts,
  selectedPack,
  selectedPromo,
  shippingPrice,
  shippingObj,
  dispatch,
}) => {
  try {
    let clonedProducts = _.cloneDeep(userProducts);
    const groupedData = clonedProducts.reduce((acc, item) => {
      if (!acc[item.brandId]) acc[item.brandId] = [];
      acc[item.brandId].push(item);
      return acc;
    }, {});

    let bodies = [];
    for (const brandId of Object.keys(groupedData)) {
      let products = groupedData[brandId];
      let shippingCharges = shippingPrice;
      if (shippingObj) shippingCharges = getShippingCharges({ ...shippingObj, products });
      let priceDetails = {};
      let apiRes = await productOrderService.getProductOrderPrice({ products });
      if (apiRes.success) priceDetails = apiRes.data;
      else {
        dispatch(snackbarInfo({ type: 'error', open: true, message: apiRes.message.message }));
        throw { bodies: [] };
      }

      priceDetails.productsDiscount = Number(productsDiscountValue(products)?.toFixed(2));
      priceDetails.discount = couponDiscountValue({
        promos: selectedPromo,
        brandId,
        shippingCharges,
        priceDetails,
      });

      priceDetails.flatDiscount = 0;
      priceDetails.shippingCharges = Number(shippingCharges) || 0;
      priceDetails.totalPrice = finalPriceHandler({
        selectedPack,
        priceDetails,
        shippingCharges: priceDetails.shippingCharges,
      });
      priceDetails.sellerAmount = 0;
      priceDetails.brandAmount = 0;

      let reqProducts = products.map((e) => {
        priceDetails.sellerAmount += ((e.discountedPrice || e.price) - e.costPrice) * e.count;
        priceDetails.brandAmount += e.costPrice * e.count;
        if (e.discount) e.discountType = e.discountType?.value || null;
        else delete e.discountType;
        return e;
      });

      priceDetails.brandAmount += priceDetails.shippingCharges;

      let data = { ...body, products: reqProducts, brand: [brandId], priceDetails };
      let reqPromo = selectedPromo?.find((e) => e.brand == brandId);
      if (reqPromo) data.couponUsed = reqPromo?._id;
      bodies.push(data);
    }
    return bodies;
  } catch (error) {
    return error.bodies;
  }
};
