import React from 'react';
import MynyfyText from './MynyfyText';
import { ContestType, FEATURE_TYPES } from '../utils/enums';
import _ from 'lodash';
import JackpotVideo from '../screens/Contest/Jackpot/JackpotVideo';
import './Webcommon.scss';
import WebPromos from '../screens/Promos/WebPromos';
import { Card } from '@mui/material';
import theme from '../utils/theme';
import { useSelector } from 'react-redux';
import { s3ToCloudFront } from '../utils/CommonMethods';

const WebMynyfyDoubleCard = ({ data, onClick, body, footer, type, cardStyle, promo }) => {
  console.log(promo);
  return (
    <div id='WebMynyfyDoubleCard'>
      <div className='card-container'>
        {data?.map((res, i) => (
          <div>
            <div className='webdCard cursor' style={cardStyle} key={i}>
              {type === FEATURE_TYPES.STORES && _.isEmpty(res.orgImages) ? (
                <div className='noImgContainer'>
                  <div>
                    <MynyfyText title={'NO IMAGE'} h4 color='#B2B2B2' />
                    <MynyfyText title={'AVAILABLE'} h4 color='#B2B2B2' />
                  </div>
                </div>
              ) : res.logo?.url || res.orgImages?.[0]?.url || res.image?.url || res.image ? (
                <div>
                  <img
                    src={s3ToCloudFront(
                      res.logo?.url || res.orgImages?.[0]?.url || res.image?.url || res.image
                    )}
                    className='webdImg'
                    onClick={() => onClick(res)}
                  />
                  <MynyfyText title={res.name} style={{ padding: '7px 10px' }} bold h6 />
                  <div>
                    {/* <div style={{ padding: '7px 10px' }}>
                  <MynyfyText title={theme.RUPEE + promo?.actualPrice + ' Off'} bold />
                  <MynyfyText
                    title={'On a min order of ' + theme.RUPEE + promo?.minimumOrderValue}
                    style={{ paddingTop: 4 }}
                  />
                </div> */}

                    {res?.policies?.shippingCharges?.outsideState?.deliveryDays && (
                      <div style={{ paddingLeft: 10, paddingBottom: 10 }}>
                        <MynyfyText
                          title={`Delivers in ${res.policies.shippingCharges.outsideState.deliveryDays} days.`}
                        />
                      </div>
                    )}
                  </div>
                </div>
              ) : res.video && res.type === ContestType.JACKPOT ? (
                <div style={{ margin: 2 }}>
                  <div style={{ borderRadius: 6, overflow: 'hidden' }}>
                    <JackpotVideo contestDetails={res} className='dImg' style={{ margin: 0 }} />
                  </div>
                </div>
              ) : (
                <div className='dImg'>{body(res)}</div>
              )}
              <div style={{ marginTop: 'auto' }}>{footer(res)}</div>
            </div>
            {/* {promo?.[0]?.actualPrice && promo?.[0]?.minimumOrderValue ? (
              <div style={{ paddingLeft: 10, paddingTop: 20, paddingBottom: 10 }}>
                <MynyfyText title={theme.RUPEE + promo?.[0]?.actualPrice + ' Off'} bold h6 />
                <MynyfyText
                  title={'On a min order of ' + theme.RUPEE + promo?.[0]?.minimumOrderValue}
                  style={{ paddingTop: 4 }}
                />
              </div>
            ) : null} */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default WebMynyfyDoubleCard;
