import _ from 'lodash';

export const productsMergeHandler = ({ products, setActualProducts, setProducts, needData }) => {
  let brandProducts = _.cloneDeep(products.filter((e) => e.brandProductId));
  let modifiedProducts = brandProducts.map((product) => {
    let reqObj = {
      ...product.brandProductId,
      brandProductId: product.brandProductId._id,
      _id: product._id,
    };

    reqObj.variants.map((variant) => {
      variant.brandActualPrice = variant.actualPrice;
      variant.brandPrice = variant.price;
      // variant.brandOrdersCount = variant.ordersCount;
      let sellerVariant = product.variants.find((e) => e.brandVariantId == variant._id);
      variant.actualPrice = sellerVariant.actualPrice;
      variant.price = sellerVariant.price;
      // variant.ordersCount = sellerVariant.ordersCount;
      variant.brandVariantId = variant._id;
      variant._id = sellerVariant._id;
    });
    return reqObj;
  });
  let storeProducts = products.filter((e) => !e.brandProductId);
  let productsData = [...storeProducts, ...modifiedProducts];
  if (setActualProducts) setActualProducts(productsData);
  if (setProducts) setProducts(productsData);
  if (needData) return productsData;
};
